import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  cue: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: "5px 10px",
    margin: "10px",
    textAlign: "center",
    display: "inline-block",
    borderRadius: "2px",
  },
  cueContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};
