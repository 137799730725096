import React, { CSSProperties, FC, ReactElement, useState } from "react";
import { styles } from "./styles";


interface ITabViewProps {
  tabs: Array<any>;
  renderTab: (tab: any) => string;
  renderView: (tab: any) => ReactElement;
  style: CSSProperties;
}

export const TabView: FC<ITabViewProps> = props => {
  const { tabs, renderTab, renderView, style, ...rest } = props;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      {...rest}
      style={{
        ...styles.root,
        ...style,
      }}
    >
      <div style={styles.center}>
        {tabs.map((tab, i) => (
          <div
            key={i}
            style={{
              ...styles.tab,
              borderBottom: i === activeTab ? "2px solid #eef" : "2px solid transparent",
            }}
            onClick={(): void => {
              setActiveTab(i);
            }}
          >
            {renderTab(tab)}
          </div>
        ))}
      </div>
      {renderView(tabs[activeTab])}
    </div>
  );
};
