import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  tabBar: {
    width: "500px",
    height: "400px",
    position: "relative",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
};
