import React, { FC, useEffect, useState } from "react";
import { LoadingIndicator } from "../../LoadingIndicator";
import * as utils from "../../../utils";
import { ISubtitle } from "../../../types/subtitle";
import { ICue } from "../../../types/cue";
import { IStream } from "../../../types/stream";
import { styles } from "./styles";


interface ISubtitleProps {
  stream: IStream;
  sub: ISubtitle;
}

export const Subtitle: FC<ISubtitleProps> = props => {
  const { sub, stream } = props;
  const [subtitle, setSubtitle] = useState<ISubtitle>(undefined!);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://api.subreader.dk/streams/${stream.id}/subtitles/${sub.language}`)
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          throw json.error;
        }
        setSubtitle(json.data);
        setIsLoading(false);
      });
  }, [sub, stream]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div style={styles.cueContainer}>
      {subtitle.cues.map((cue: ICue) => (
        <div style={styles.cue} key={cue._id}>
          <div style={{ fontSize: "12px" }}>{`${utils.formatTime(cue.timeIn)} → ${utils.formatTime(cue.timeOut)}`}</div>
          <span>{cue.text}</span>
        </div>
      ))}
    </div>
  );
};
