import React, { FC, ReactElement, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { LoadingIndicator } from "../LoadingIndicator";
import { Card } from "../Card";
import { TabView } from "./TabView";
import { StatusIndicator } from "../StatusIndicator";
import { Subtitle } from "./Subtitle";
import { ISubtitle } from "../../types/subtitle";
import { IStream } from "../../types/stream";
import { styles } from "./styles";


export const Stream: FC = () => {
  const [stream, setStream] = useState<IStream>(undefined!);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.subreader.dk/streams/${window.location.hash.slice(1)}`)
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          throw json.error;
        }
        setStream(json.data);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StatusIndicator
          diameter={10}
          stream={stream}
          style={{
            marginRight: "10px",
          }}
        />
        <h1>{stream.name}</h1>
      </div>
      <div style={styles.cardContainer}>
        <Card style={{ marginRight: "20px" }}>
          <h2>Undertekster</h2>
          {stream.subtitles.length ? (
            <TabView
              style={styles.tabBar}
              tabs={stream.subtitles}
              renderTab={(sub: ISubtitle): string => sub.language}
              renderView={(sub: ISubtitle): ReactElement => <Subtitle sub={sub} stream={stream} />}
            />
          ) : (
            <div>Ingen undertekster</div>
          )}
        </Card>
        <Card style={{ marginRight: "20px" }}>
          <h2>QR</h2>
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <QRCode value={`subreader://${stream.id}`} level="L" size={256} bgColor="transparent" />
          </div>
        </Card>
        <Card>
          <h2>Raw</h2>
          <SyntaxHighlighter language="json" style={monoBlue}>
            {JSON.stringify(stream, null, 2)}
          </SyntaxHighlighter>
        </Card>
      </div>
    </div>
  );
};
