import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  root: {
    overflowY: "scroll",
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  tab: {
    flex: 1,
    textAlign: "center",
    margin: "5px 10px",
    cursor: "pointer",
  },
};
