export function zeroPad(zeros: number, number: number): string {
  if (number >= Math.pow(10, zeros)) {
    return `${number}`;
  }
  return `${new Array(zeros).fill(0).join("")}${number}`.slice(-zeros);
}

export function getTimeObject(time: number): { milliseconds: number; seconds: number; minutes: number; hours: number } {
  const milliseconds = time % 1000;
  const seconds = ~~((time / 1000) % 60);
  const minutes = ~~((time / (1000 * 60)) % 60);
  const hours = ~~((time / (1000 * 60 * 60)) % 24);
  return { milliseconds, seconds, minutes, hours };
}

export function formatTime(duration: number): string {
  const { hours, minutes, seconds, milliseconds } = getTimeObject(duration);
  return [zeroPad(2, hours), zeroPad(2, minutes), zeroPad(2, seconds), zeroPad(3, milliseconds)].join(":");
}
